import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "./index.css"

const IndexPage = () => ({

    render() {
      const { data } = this.props
      const siteTitle = data.site.siteMetadata.title
      const posts = data.allMdx.edges

      return (
        <Layout location={this.props.location} title={siteTitle}>
          <SEO title="Afarer" />

          <div className="page">
          <img className="page__img" src="/og-image.png"></img>
            {posts.map(({ node }) => {
              const title = node.frontmatter.title || node.fields.slug
              return (
                  <Link className="page__link"
                    to={`blog${node.fields.slug}`}
                  >
                    {title}
                  </Link>
              )
            })}
          </div>
        </Layout>
      )
    }
  }
)

export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
